import React from 'react'
import { css } from '@emotion/core'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import { companyEmail } from '#globals/constants'

const styles = {
  container: css`
    ${breakPoints({
      paddingTop: [40, 40, 90, 110, 110],
      paddingBottom: [40, 40, 90, 110, 110],
    })};
  `,
  section: css`
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    ul {
      padding-left: 30px;
      list-style-type: circle;
    }
    ol {
      padding-left: 30px;
      list-style-type: decimal;
    }
  `,
}
function Terms() {
  return (
    <div css={styles.container}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 offset-md-0'>
            <section css={styles.section}>
              <Title
                size='xxxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Terms and Conditions
              </Title>
              <Text size='medium' color={colors.charcoal}>
                Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Neque ullam doloribus molestias repellat, at
                illum quasi vero cumque nisi temporibus, assumenda
                provident omnis aliquam saepe quos eos porro excepturi
                eligendi?
              </Text>
            </section>

            <section css={styles.section}>
              <Text size='medium' color={colors.charcoal}>
                Please read the following terms and conditions for
                usage of our site very carefully. Your use of service
                is subject to your acceptance of and compliance with
                the following terms and conditions (“Terms”). By
                subscribing to or using any of our services you agree
                that you have read, understood and are bound by the
                Terms, regardless of how you subscribe to or use the
                services. If you do not want to be bound by the Terms,
                you must not subscribe to or use our services. In
                these Terms, references to “you”, “User” shall mean
                the end user accessing the Website, its contents and
                using the Services offered through the Website.
                “Service Providers” mean independent third party
                service providers, and “we”, “us” and “our” shall mean
                Daily Quest Plus, its franchisor, affiliates and
                partners.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Introduction:
              </Title>
              <Text as='ol' size='medium' color={colors.charcoal}>
                <Text as='li' size='medium' color={colors.charcoal}>
                  The www.dailyquestplus.co.uk website (“Website”) is
                  an Internet based content and e-commerce portal
                  operated by Exam Papers Plus Limited, a company
                  incorporated under the laws of the UK.
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Use of the Website is offered to you conditioned on
                  acceptance without modification of all the terms,
                  conditions and notices contained in these Terms, as
                  may be posted on the Website from time to time.
                  dailyquestplus.co.uk at its sole discretion reserves
                  the right not to accept a User from registering on
                  the Website without assigning any reason thereof.
                </Text>
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                User Account, Password, and Security:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                You are responsible for maintaining the
                confidentiality of the password and account, and are
                fully responsible for all activities that occur under
                your password or account. You agree to (a) immediately
                notify dailyquestplus.co.uk of any unauthorised use of
                your password or account or any other breach of
                security, and (b) ensure that you exit from your
                account at the end of each session.
                dailyquestplus.co.uk cannot and will not be liable for
                any loss or damage arising from your failure to comply
                with this.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Services Offered:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                dailyquestplus.co.uk provides a number of
                Internet-based services through the Web Site (all such
                services, collectively, the “Service”). One such
                service enables users to purchase original merchandise
                such as home furniture (collectively, “Products”).
                Upon placing an order, dailyquestplus.co.uk shall ship
                the product to you and be entitled to its payment for
                the Services.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Privacy Policy:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The User hereby consents, expresses and agrees that he
                has read and fully understands the Privacy Policy of
                dailyquestplus.co.uk the Website. The user further
                consents that the terms and contents of such Privacy
                Policy are acceptable to him
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Limited User:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The User agrees and undertakes not to reverse
                engineer, modify, copy, distribute, transmit, display,
                perform, reproduce, publish, license, create
                derivative works from, transfer, or sell any
                information, products or software obtained from the
                Website. Limited reproduction and copying of the
                content of the Website is permitted provided that
                dailyquestplus.co.uk’s name is stated as the source
                and prior written permission of dailyquestplus.co.uk
                is sought. For the removal of doubt, it is clarified
                that unlimited or wholesale reproduction, copying of
                the content for commercial or non-commercial purposes
                and unwarranted modification of data and information
                within the content of the Website is not permitted.
                Products are licensed for personal use for one
                individual. Forwarding and distributing to others to
                use is against the terms of service and will result in
                the order becoming invalid. Such distribution breaches
                our copyright and intellectual property.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                User Conduct and Rules:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                You agree and undertake to use the Website and the
                Service only to post and upload messages and material
                that are proper. By way of example, and not as a
                limitation, you agree and undertake that when using a
                Service, you will not:
              </Text>
              <Text as='ol' size='medium' color={colors.charcoal}>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Defame, abuse, harass, stalk, threaten or otherwise
                  violate the legal rights of others;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing,
                  obscene, indecent or unlawful topic, name, material
                  or information;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Upload files that contain software or other material
                  protected by intellectual property laws unless you
                  own or control the rights thereto or have received
                  all necessary consents;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Upload or distribute files that contain viruses,
                  corrupted files, or any other similar software or
                  programs that may damage the operation of the
                  Website or another’s computer;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Conduct or forward surveys, contests, pyramid
                  schemes or chain letters;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Download any file posted by another user of a
                  Service that you know, or reasonably should know,
                  cannot be legally distributed in such manner;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Falsify or delete any author attributions, legal or
                  other proper notices or proprietary designations or
                  labels of the origin or source of software or other
                  material contained in a file that is uploaded;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Violate any code of conduct or other guidelines,
                  which may be applicable for or to any particular
                  Service;
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Violate any applicable laws or regulations for the
                  time being in force in or outside India; and
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Violate any of the terms and conditions of this
                  Agreement or any other terms and conditions for the
                  use of the Website contained elsewhere herein.
                </Text>
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                User Warranty and Representation:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The user guarantees, warrants, and certifies that you
                are the owner of the content which you submit or
                otherwise authorized to use the content and that the
                content does not infringe upon the property rights,
                intellectual property rights or other rights of
                others. You further warrant that to your knowledge, no
                action, suit, proceeding, or investigation has been
                instituted or threatened relating to any content,
                including trademark, trade name service mark, and
                copyright formerly or currently used by you in
                connection with the Services rendered by
                dailyquestplus.co.uk.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Exactness Not Guaranteed:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                dailyquestplus.co.uk hereby disclaims any guarantees
                of exactness as to the finish and appearance of the
                final Product as ordered by the user. The quality of
                any products, Services, information, or other material
                purchased or obtained by you through the Website may
                not meet your expectations. In the case of any
                significant material damage to the Product, the
                customer must send photographic evidence of the
                damaged product via email to dailyquestplus.co.uk
                within 7 days of receiving the Product. A
                representative from dailyquestplus.co.uk will review
                the evidence, and if it is found valid,
                dailyquestplus.co.uk will send an email communication
                to you requesting for a replacement. You agree in this
                instance, that dailyquestplus.co.uk will make the
                final judgment on whether a Product has been
                significantly damaged.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Intellectual Property Rights:
              </Title>
              <Text size='medium' color={colors.charcoal} />
              <Text as='ol' size='medium' color={colors.charcoal}>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Unless otherwise indicated or anything contained to
                  the contrary or any proprietary material owned by a
                  third party and so expressly mentioned,
                  dailyquestplus.co.uk owns all Intellectual Property
                  Rights to and into the Website, including, without
                  limitation, any and all rights, title and interest
                  in and to copyright, related rights, patents,
                  utility models, trademarks, trade names, service
                  marks, designs, know-how, trade secrets and
                  inventions (whether patentable or not), goodwill,
                  source code, meta tags, databases, text, content,
                  graphics, icons, and hyperlinks. You acknowledge and
                  agree that you shall not use, reproduce or
                  distribute any content from the Website belonging to
                  dailyquestplus.co.uk without obtaining authorization
                  from dailyquestplus.co.uk.
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  Notwithstanding the foregoing, it is expressly
                  clarified that you will retain ownership and shall
                  solely be responsible for any content that you
                  provide or upload when using any Service, including
                  any text, data, information, images, photographs,
                  music, sound, video or any other material which you
                  may upload, transmit or store when making use of our
                  various Service. However, you expressly agree that
                  by uploading and posting content on to the Website
                  for public viewing and reproduction/use of your
                  content by third party users, you accept the User
                  whereby you grant a non-exclusive license for the
                  use of the same.
                </Text>
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Links To Third Party Sites:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The Website may contain links to other websites
                (“Linked Sites”).The Linked Sites are not under the
                control of dailyquestplus.co.uk or the Website and
                dailyquestplus.co.uk is not responsible for the
                contents of any Linked Site, including without
                limitation any link contained in a Linked Site, or any
                changes or updates to a Linked Site.
                dailyquestplus.co.uk is not responsible for any form
                of transmission, whatsoever, received by you from any
                Linked Site. dailyquestplus.co.uk is providing these
                links to you only as a convenience, and the inclusion
                of any link does not imply endorsement by
                dailyquestplus.co.uk or the Website of the Linked
                Sites or any association with its operators or owners
                including the legal heirs or assigns thereof. The
                users are requested to verify the accuracy of all
                information on their own before undertaking any
                reliance on such information.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Disclaimer Of Warranties/Limitation Of Liability:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                dailyquestplus.co.uk has endeavoured to ensure that
                all the information on the Website is correct, but
                dailyquestplus.co.uk neither warrants nor makes any
                representations regarding the quality, accuracy or
                completeness of any data, information, product or
                Service. In no event shall dailyquestplus.co.uk be
                liable for any direct, indirect, punitive, incidental,
                special, consequential damages or any other damages
                resulting from: (a) the use or the inability to use
                the Services; (b) unauthorized access to or alteration
                of the user’s transmissions or data; (c) any other
                matter relating to the services; including, without
                limitation, damages for loss of use, data or profits,
                arising out of or in any way connected with the use or
                performance of the Website or Service. Neither shall
                dailyquestplus.co.uk be responsible for the delay or
                inability to use the Website or related services, the
                provision of or failure to provide Services, or for
                any information, software, products, services and
                related graphics obtained through the Website, or
                otherwise arising out of the use of the website,
                whether based on contract, tort, negligence, strict
                liability or otherwise. Further, dailyquestplus.co.uk
                shall not be held responsible for non-availability of
                the Website during periodic maintenance operations or
                any unplanned suspension of access to the website that
                may occur due to technical reasons or for any reason
                beyond dailyquestplus.co.uk’s control. The user
                understands and agrees that any material and/or data
                downloaded or otherwise obtained through the Website
                is done entirely at their own discretion and risk and
                they will be solely responsible for any damage to
                their computer systems or loss of data that results
                from the download of such material and/or data.We as a
                merchant shall be under no liability whatsoever in
                respect of any loss or damage arising directly or
                indirectly out of the decline of authorization for any
                Transaction, on Account of the Cardholder having
                exceeded the preset limit mutually agreed by us with
                our acquiring bank from time to time
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Indemnification:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                You agree to indemnify, defend and hold harmless
                dailyquestplus.co.uk from and against any and all
                losses, liabilities, claims, damages, costs and
                expenses (including legal fees and disbursements in
                connection therewith and interest chargeable thereon)
                asserted against or incurred by dailyquestplus.co.uk
                that arise out of, result from, or may be payable by
                virtue of, any breach or non-performance of any
                representation, warranty, covenant or agreement made
                or obligation to be performed by you pursuant to these
                Terms.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Pricing:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                Prices for products are described on our Website and
                are incorporated into these Terms by reference. All
                prices are in British pounds. Prices, Products and
                Services may change at dailyquestplus.co.uk’s
                discretion.Any discount code / cashback that is
                applied on a specific order has a maximum limit of 1
                code / coupon / cashback per order.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Refunds:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                Due to the nature of digital products, we can
                unfortunately not offer refunds or returns on any
                products once they have been purchased. If you are
                unsatisfied with the product or are facing technical
                issues or have any other concerns, please contact us
                at info@dailyquestplus.co.uk and we will be delighted
                to help resolve the problem.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Shipping:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                Title and risk of loss for all products ordered by you
                shall pass on to you upon dailyquestplus.co.uk’s
                shipment to the shipping carrier in the cad of
                physical products. We exclusively sell e-papers which
                are delivered digitally.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Termination:
              </Title>
              <Text as='ol' size='medium' color={colors.charcoal}>
                <Text as='li' size='medium' color={colors.charcoal}>
                  dailyquestplus.co.uk may suspend or terminate your
                  use of the Website or any Service if it believes, in
                  its sole and absolute discretion that you have
                  breached any of the Terms.
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  If you or dailyquestplus.co.uk terminates your use
                  of the Website or any Service, dailyquestplus.co.uk
                  may delete any content or other materials relating
                  to your use of the Service and dailyquestplus.co.uk
                  will have no liability to you or any third party for
                  doing so.
                </Text>
                <Text as='li' size='medium' color={colors.charcoal}>
                  You shall be liable to pay for any Service or
                  product that you have already ordered till the time
                  of Termination by either party whatsoever. Further,
                  you shall be entitled to your royalty payments as
                  per the User License Agreement that has or is
                  legally deemed accrued to you.
                </Text>
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Governing Law:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                These terms shall be governed by and constructed in
                accordance with the laws of the UK without reference
                to conflict of laws principles and disputes arising in
                relation hereto shall be subject to the exclusive
                jurisdiction of the courts in the UK.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Headings:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The headings and subheadings herein are included for
                convenience and identification only and are not
                intended to describe, interpret, define or limit the
                scope, extent or intent of the Terms or the right to
                use the Website by you contained herein or any other
                section or pages of the Website or any Linked Sites in
                any manner whatsoever.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Interpretation Of Number And Genders:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                The Terms herein shall apply equally to both the
                singular and plural form of the terms defined.
                Whenever the context may require, any pronoun shall
                include the corresponding masculine and feminine. The
                words “include”, “includes” and “including” shall be
                deemed to be followed by the phrase “without
                limitation”. Unless the context otherwise requires,
                the terms “herein”, “hereof”, “hereto”, “hereunder”
                and words of similar import refer to the Terms as a
                whole.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Severability:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                If any provision of the Terms is determined to be
                invalid or unenforceable in whole or in part, such
                invalidity or unenforceability shall attach only to
                such provision or part of such provision and the
                remaining part of such provision and all other
                provisions of these Terms shall continue to be in full
                force and effect.
              </Text>
            </section>

            <section css={styles.section}>
              <Title
                size='xxLarge'
                color={colors.black}
                css={spacer.mrB20}
              >
                Report Abuse:
              </Title>
              <Text size='medium' color={colors.charcoal}>
                As per these Terms, users are solely responsible for
                every material or content uploaded on to the Website.
                dailyquestplus.co.uk does not review the contents in
                any way before they appear on the Website.
                dailyquestplus.co.uk does not verify, endorse or
                otherwise vouch for the contents of any user or any
                content generally posted or uploaded on to the
                Website. Users can be held legally liable for their
                contents and may be held legally accountable if their
                contents or material include, for example, defamatory
                comments or material protected by copyright,
                trademark, etc. If you come across any abuse or
                violation of these Terms, please report to{' '}
                <ExternalLink
                  to={`mailto:${companyEmail}`}
                  hoverColor='black'
                  borderColor={colors.charcoal}
                >
                  {companyEmail}
                </ExternalLink>
              </Text>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms
