import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import Terms from '#containers/Terms/Terms'

const SignupPage = () => (
  <PublicLayout headerColor={colors.white}>
    <SEO
      title='Terms and Conditions'
      meta={[{ name: 'theme-color', content: colors.white }]}
    />
    <Terms />
  </PublicLayout>
)

export default SignupPage
